import type { EntityServiceResponseError } from '@/services/blackfisk'

import { useError } from '@/composables/useError'

export const useRequestErrorToast = (
  errors: EntityServiceResponseError[],
  summary = 'Error'
) => {
  const { errorToast } = useError()
  for (const error of errors) {
    errorToast({
      message: summary,
      messageDetail: error.message,
    })
  }
}
