<script setup lang="ts">
  import type { DynamicDialogInstance } from 'primevue/dynamicdialogoptions'
  import type { Ref } from 'vue'

  import { useRouter } from 'vue-router'
  import { computed, inject, onMounted, ref, useTemplateRef } from 'vue'

  import FormInputText from '@/components/FormInputText.vue'
  import Button from 'primevue/button'

  import { useIdentityStore } from '@/stores/identity'
  import { useLoadingStore } from '@/stores/loading'

  const router = useRouter()
  const LoadingStore = useLoadingStore()
  const IdentityStore = useIdentityStore()
  const dialogRef: Ref<DynamicDialogInstance> | undefined = inject('dialogRef')
  const loginPinInput = useTemplateRef('loginPinInput')
  const userId = ref(0)
  const checksum = ref('')
  const pin = ref('')
  const hasValidLoginInput = computed(() => {
    return userId.value > 0 && pin.value.length > 0
  })
  const focusPinInput = () => {
    loginPinInput.value?.$el.focus()
  }
  onMounted(() => {
    if (dialogRef) {
      const props = dialogRef.value.data as { userId: number; checksum: string }
      userId.value = props.userId
      checksum.value = props.checksum
    }
    focusPinInput()
  })
  const resetFormValues = () => {
    pin.value = ''
  }
  const handleQuickLoginSubmit = async () => {
    const loginPayload = {
      userId: userId.value,
      checksum: checksum.value,
      pin: +pin.value,
    }
    console.log({ loginPayload })
    LoadingStore.startLoader('quick-login')
    await IdentityStore.quickLogin(loginPayload)
    resetFormValues()
    LoadingStore.stopLoader('quick-login')
    // * Redirect to stored Return URL
    if (IdentityStore.returnUrl) {
      router.push(IdentityStore.returnUrl || '/')
      IdentityStore.returnUrl = ''
    } else if (
      typeof router.currentRoute.value.name === 'string' &&
      ['login', 'home', 'about'].includes(router.currentRoute.value.name)
    ) {
      router.replace({
        name: 'dashboard',
      })
    }
    if (dialogRef) {
      dialogRef.value.close()
    }
  }
</script>

<template>
  <div>
    <form id="login-form" @submit.prevent="handleQuickLoginSubmit">
      <div class="flex flex-col gap-2">
        <label for="login-pin">PIN</label>
        <FormInputText
          id="login-pin"
          ref="loginPinInput"
          v-model="pin"
          placeholder="PIN"
          aria-label="Login PIN"
          size="large"
          type="password"
          :disabled="LoadingStore.isLoaderRunning('quick-login')"
        />
        <small
          id="login-pin-help"
          class="text-surface-400 dark:text-surface-300"
          >Please enter your login PIN.</small
        >
      </div>
      <div class="mt-10 flex w-full justify-center">
        <Button
          id="quick-login-form-submit"
          label="Login"
          :disabled="
            !hasValidLoginInput || LoadingStore.isLoaderRunning('quick-login')
          "
          severity="info"
          size="large"
          type="submit"
        >
        </Button>
      </div>
    </form>
  </div>
</template>
