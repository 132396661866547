<script setup lang="ts">
  import FormDropdown from '@/components/FormDropdown.vue'
  import { useAccount } from '@/composables/useAccount'
  import { useAuthorizationSync } from '@/composables/useAuthorizationSync'

  import { useIdentityStore } from '@/stores/identity'

  const { updateAccount } = useAccount()

  const IdentityStore = useIdentityStore()
  const { broadcastAccountId } = useAuthorizationSync()
  const onAccountChange = async (accountId: number) => {
    await updateAccount(accountId)
    broadcastAccountId(accountId)
  }
</script>

<template>
  <FormDropdown
    v-if="IdentityStore.isAuthenticated"
    :modelValue="IdentityStore.accountId"
    :options="IdentityStore.accounts"
    optionLabel="name"
    optionValue="id"
    placeholder="Select an Account"
    class="w-[10rem] items-center text-center"
    aria-label="Account Selection"
    @update:modelValue="onAccountChange"
  >
    <!-- TODO: Use PT to style. -->
  </FormDropdown>
</template>

<style lang="postcss" scoped></style>
