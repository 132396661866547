import { ref } from 'vue'

export interface LoggedError {
  message: string
  timestamp: Date
}
const errorMessageLog = ref<LoggedError[]>([])
export const useErrorLog = () => {
  const logErrorMessage = (errorMessage: string[]) => {
    if (errorMessage.length > 0) {
      for (const individualErrorMessage of errorMessage) {
        if (individualErrorMessage.length > 0) {
          errorMessageLog.value.push({
            message: individualErrorMessage,
            timestamp: new Date(),
          })
        }
      }
    }
  }
  return {
    logErrorMessage,
    errorMessageLog,
  }
}
