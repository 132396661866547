import { ref } from 'vue'
import { defineStore } from 'pinia'

export type Theme = 'light' | 'dark'
export type ThemesList = Theme[]
const toggleBodyClass = (isEnable: boolean, className: string) => {
  const bodyElement = document.body
  if (isEnable && !bodyElement.classList.contains(className)) {
    bodyElement.classList.add(className)
  } else if (!isEnable) {
    bodyElement.classList.remove(className)
  }
  const htmlElement = document.documentElement
  if (isEnable && !htmlElement.classList.contains(className)) {
    htmlElement.classList.add(className)
  } else if (!isEnable) {
    htmlElement.classList.remove(className)
  }
}
export const useThemeStore = defineStore(
  'theme',
  () => {
    const theme = ref('light')
    const themes: ThemesList = ['light', 'dark']
    function themeSet(desiredTheme: Theme) {
      if (themes.includes(desiredTheme)) {
        theme.value = desiredTheme
        toggleBodyClass(desiredTheme === 'dark', 'dark')
      }
    }
    return { theme, themes, themeSet }
  },
  {
    persist: {
      storage: localStorage,
      afterHydrate: (context) => {
        toggleBodyClass(context.store.theme === 'dark', 'dark')
      },
    },
  }
)
