import type { RouteRecordRaw } from 'vue-router'

import HomeView from '@/views/HomeView.vue'

// * Vite Glob Impors gather routes.ts from modules: https://vite.dev/guide/features#named-imports
const moduleRoutes = import.meta.glob('../modules/**/routes.ts', {
  import: 'routes',
  eager: true,
})
const moduleNamedRoutes = Object.entries(
  moduleRoutes as Record<string, RouteRecordRaw[]>
).reduce((accumulator: Record<string, RouteRecordRaw>, [, routes]) => {
  for (const route of routes) {
    if (route.name && typeof route.name === 'string') {
      accumulator[route.name] = route
    }
  }
  return accumulator
}, {})

export const namedRoutes: Record<string, RouteRecordRaw> = {
  'home': {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: false, requiredRoles: [], requiredGroups: [] },
  },
  'about': {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: { requiresAuth: false, requiredRoles: [], requiredGroups: [] },
  },
  'dashboard': {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/AppDashboard.vue'),
    meta: { requiresAuth: true, requiredRoles: [], requiredGroups: [] },
  },
  'login': {
    path: '/login',
    name: 'login',
    component: () => import('../views/AppLogin.vue'),
    meta: { requiresAuth: false, requiredRoles: [], requiredGroups: [] },
  },
  'user-settings': {
    path: '/user-settings',
    name: 'user-settings',
    component: () => import('../views/UserSettings.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [],
      requiredGroups: ['adminUserAccess'],
    },
  },
  ...moduleNamedRoutes,
}
export const routes = Object.values(namedRoutes)
