export const routes = [
  {
    path: '/promotion/manage',
    name: 'promotion-manager',
    component: () => import('./views/PromotionManager.vue'),
    meta: { requiresAuth: true, requiredRoles: [], requiredGroups: [] },
  },
  {
    path: '/promotion/:id',
    name: 'promotion-editor',
    component: () => import('./views/PromotionEditor.vue'),
    meta: { requiresAuth: true, requiredRoles: [], requiredGroups: [] },
  },
]
