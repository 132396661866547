export const routes = [
  {
    path: '/listing-events/schedule',
    name: 'schedule-listing-events',
    component: () => import('@/views/ListingEventsScheduler.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [],
      requiredGroups: [],
    },
  },
  {
    path: '/listing-events/schedule/:pricingEventScheduleId',
    name: 'listing-event-schedule',
    component: () =>
      import(
        '@/modules/listingEvents/components/ListingEventScheduleFetcher.vue'
      ),
    meta: {
      requiresAuth: true,
      requiredRoles: [],
      requiredGroups: [],
    },
  },
]
