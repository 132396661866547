import type { User } from '@/entities/user'

import { blackfiskAxiosRequest } from '@/services/blackfisk'

export interface UserRole {
  id: number
  role: string
  roleTypeId: number
  modifiedAt: string
  modifiedBy: number
  createdAt: string
  createdBy: number
  description: string
}
export interface UserAccount {
  id: number
  name: string
  ianaTimeZoneString: string
}
export interface LoggedInUser extends User {
  roles: UserRole[]
  accounts: UserAccount[]
  token: string
}

export async function authLogin({ email = '', password = '' } = {}) {
  if (!email || !password) {
    throw new Error('Login requires an email and password.')
  }
  const { errors, data } = await blackfiskAxiosRequest<
    LoggedInUser[],
    LoggedInUser | null
  >(
    {
      method: 'POST',
      url: '/v1/blackfisk/user/login',
      data: {
        email,
        password,
      },
    },
    (response) => {
      return response.data?.[0] ?? null
    }
  )
  return {
    errors,
    user: data,
  }
}
export async function pinLogin({ userId = 0, pin = 0, checksum = '' } = {}) {
  if (!userId || !pin || !checksum) {
    throw new Error('Pin login requires a userId, pin, and checksum.')
  }
  const { errors, data } = await blackfiskAxiosRequest<
    LoggedInUser[],
    LoggedInUser | null
  >(
    {
      method: 'POST',
      url: '/v1/blackfisk/user/quickLogin',
      data: {
        id: userId,
        pin,
        checksum,
      },
    },
    (response) => {
      return response.data?.[0] ?? null
    }
  )
  return {
    errors,
    user: data,
  }
}
