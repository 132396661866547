/*
  # This composable controls re-rendering components when a user switches account.
  - accountVersion should be bound to the RouterView :key
  - any component that needs to perform async cleanup steps when the account changes can use this
  - example use case: the component relies on account-specific URL query parameters. Because route.replace is async it cannot be handled by e.g. watch(accountId, () => {}, { flush: 'sync' })
*/

import { onBeforeUnmount, ref } from 'vue'

import { useIdentityStore } from '@/stores/identity'

let CleanupSteps = new Map<symbol, () => Promise<void>>()
const accountVersion = ref(0)
export const useAccount = (key?: symbol, cleanupStep?: () => Promise<void>) => {
  if (key && cleanupStep) {
    // * Add cleanup step to be executed when account changes.
    CleanupSteps.set(key, cleanupStep)
    // * Remove cleanup step if component is destroyed.
    onBeforeUnmount(() => {
      CleanupSteps.delete(key)
    })
  }
  const IdentityStore = useIdentityStore()
  const updateAccount = async (updatedAccountId: number) => {
    IdentityStore.updateAccountId(updatedAccountId)
    // accountId.value = updatedAccountId
    await Promise.allSettled(
      Array.from(CleanupSteps.values()).map((promise) => promise())
    )
    // * Reset CleanupSteps because accountVersion triggers recreation of all components. This fallback will re-register the cleanup steps associated with those components in case any are not handle during onBeforeUnmount.
    CleanupSteps = new Map()
    accountVersion.value += 1
  }

  return {
    accountVersion,
    updateAccount,
    CleanupSteps,
  }
}
