import { useRequestErrorToast } from './useRequestErrorToast'

import { storeToRefs } from 'pinia'

import { printProductBarcodeLabel } from '@/services/blackfisk/printer'

import { usePrinterStore } from '@/stores/printer'

export const usePrinters = () => {
  const PrinterStore = usePrinterStore()
  const { labelConfiguration, refreshPrinters, refreshPapers } = PrinterStore
  const { accountPrinters, papers, labelPrinterMap } = storeToRefs(PrinterStore)
  const refreshPrintersAndPaper = () => {
    // ? Will these run only when needed?
    // * Caching controlled by server header.
    refreshPrinters()
    refreshPapers()
  }
  const printProductBarcode = async (
    serverPrinterId = 0,
    barcode = '',
    sku = '',
    title = ''
  ) => {
    if (
      serverPrinterId > 0 &&
      barcode.length > 0 &&
      sku.length > 0 &&
      title.length > 0
    ) {
      const errors = await printProductBarcodeLabel(
        serverPrinterId,
        barcode,
        sku,
        title
      )
      if (errors) {
        useRequestErrorToast(errors, 'Error printing Product Barcode')
      }
    }
  }
  const printRetailBarcode = async (
    serverPrinterId = 0,
    productId = 0,
    channelId = 0
  ) => {
    if (serverPrinterId > 0 && productId > 0 && channelId > 0) {
      // TODO: Blackfisk print
      // /v1/blackfisk/print/printProductPackageLabel
    }
  }
  return {
    refreshPrintersAndPaper,
    printProductBarcode,
    printRetailBarcode,
    labelConfiguration,
    accountPrinters,
    papers,
    labelPrinterMap,
  }
}
