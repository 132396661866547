export const routes = [
  {
    path: '/amazon/competitive/reporting',
    name: 'amazon-competitive-reporting',
    component: () => import('./views/AmazonCompetitiveReporting.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [],
      requiredGroups: ['adminUserAccess'],
    },
  },
]
