import type {
  BinScanFormat,
  DivisionMeta,
  DivisionScanFormat,
  GenericScanFormat,
  InboundReturnScanFormat,
  MerchantCreditScanFormat,
  ProductScanFormat,
  PurchaseOrderScanFormat,
  PurchaseOrderSessionScanFormat,
  ScanResult,
  ScanType,
  ServiceTicketScanFormat,
  TrackingScanFormat,
  UserLoginScanFormat,
} from '@/utils/barcode.types'

import { merchantCreditTypeDictionary } from '@/entities/merchantCreditType/constants'

export const formatProductBarcode = (
  barcode: string,
  type: ProductScanFormat['subtype'] = 'GTIN-16'
) => {
  let formattedBarcode = '0000000000000000' + barcode
  let typeLength = 16
  switch (type) {
    case 'GTIN-12':
      typeLength = 12
      break
    case 'GTIN-13':
      typeLength = 13
      break
    case 'GTIN-16':
      typeLength = 16
      break
  }
  formattedBarcode = formattedBarcode.substring(
    formattedBarcode.length - typeLength,
    formattedBarcode.length
  )
  return formattedBarcode
}

// checksum calculation for GTIN-8, GTIN-12, GTIN-13, GTIN-14, and SSCC
// based on http://www.gs1.org/barcodes/support/check_digit_calculator
// https://gist.githubusercontent.com/spig/897768/raw/d294f7902584d83a040f7f7dcf5371bc345f397f/validate_barcode.js
// https://gist.github.com/spig/897768
export const isValidGTIN = (barcode = '') => {
  if (
    barcode.length < 8 ||
    barcode.length > 18 ||
    (barcode.length !== 8 &&
      barcode.length !== 12 &&
      barcode.length !== 13 &&
      barcode.length !== 14 &&
      barcode.length !== 18)
  ) {
    return false
  }

  const lastDigit = Number(barcode.substring(barcode.length - 1))
  let checkSum = 0
  if (isNaN(lastDigit)) {
    return false
  }

  const arr = barcode
    .substring(0, barcode.length - 1)
    .split('')
    .reverse()
  let oddTotal = 0
  let evenTotal = 0

  for (let i = 0; i < arr.length; i++) {
    if (isNaN(+arr[i])) {
      return false
    }
    if (i % 2 === 0) {
      oddTotal += Number(arr[i]) * 3
    } else {
      evenTotal += Number(arr[i])
    }
  }
  checkSum = (10 - ((evenTotal + oddTotal) % 10)) % 10
  return checkSum === lastDigit
}
export const portableBinPrefixes: Record<string, BinScanFormat['subtype']> = {
  B: 'Bin',
  N: 'Receiving',
  R: 'Return',
  T: 'Transfer',
  F: 'FBA',
  Q: 'Quarantine',
  C: 'ChannelOrder',
  S: 'SalesPerson',
}
export const internalBarcodeScanTest = (barcode = '') => {
  return barcode[0] === '/' && barcode[barcode.length - 1] === '/'
}
// USPS Tracking  9400100000000000000000
const isUSPSTracking = RegExp(/^94001[0-9]{17}\b/)
// Priority Mail  9205500000000000000000
const isUSPSPriorityMail = RegExp(/^92055[0-9]{17}\b/)
// Certified Mail 9407300000000000000000
const isUSPSCertifiedMail = RegExp(/^94073[0-9]{17}\b/)
// Collect On Delivery Hold For Pickup 9303300000000000000000
const isUSPSCollectOnDeliveryHoldForPickup = RegExp(/^93033[0-9]{17}\b/)
// Global Express Guaranteed  8200000000
const isUSPSGlobalExpressGuaranteed = RegExp(/^82[0-9]{8}\b/)
// Priority Mail Express International  EC000000000US
const isUSPSPriorityMailExpressInternational = RegExp(/^EX[0-9]{9}US\b/)
// Priority Mail Express  9270100000000000000000, EA000000000US
const isUSPSPriorityMailExpress = RegExp(/^92701[0-9]{17}\b/)
const isUSPSPriorityMailExpress2 = RegExp(/^EA[0-9]{9}US\b/)
// Priority Mail International  CP000000000US
const isUSPSPriorityMailInternational = RegExp(/^CP[0-9]{9}US\b/)
// Registered Mail  9208800000000000000000
const isUSPSRegisteredMail = RegExp(/^92088[0-9]{17}\b/)
// Signature Confirmation 9202100000000000000000
const isUSPSSignatureConfirmation = RegExp(/^92021[0-9]{17}\b/)
// Ground 9622001560001234567100794808390594, 9622001560001234567100794808390594, 9632001560123456789900794808390594
const isFedExGround = RegExp(/^96[22|32][0-9]{30}\b/)
const isUPS = RegExp(
  /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/
)
const isFedEx1 = /(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/
const isFedEx2 = RegExp(
  /\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/
)
const isFedEx3 = RegExp(/^[0-9]{15}\b/)
const isUSPS1 = RegExp(/(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/)
const isUSPS2 = RegExp(/^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/)
const isUSPS3 = RegExp(/^91[0-9]+$/)
const isUSPS4 = RegExp(/^[A-Za-z]{2}[0-9]+US$/)
export const isFedExTrackingNumber = (barcode = '') => {
  return (
    (barcode.match(isFedEx1)?.length ?? 0) > 0 ||
    (barcode.match(isFedEx2)?.length ?? 0) > 0 ||
    (barcode.match(isFedEx3)?.length ?? 0) > 0 ||
    (barcode.match(isFedExGround)?.length ?? 0) > 0
  )
}
export const isUPSTrackingNumber = (barcode = '') => {
  return (barcode.match(isUPS)?.length ?? 0) > 0
}
export const isUSPSTrackingNumber = (barcode = '') => {
  return (
    (barcode.match(isUSPSTracking)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSPriorityMail)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSCertifiedMail)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSCollectOnDeliveryHoldForPickup)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSGlobalExpressGuaranteed)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSPriorityMailExpressInternational)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSPriorityMailExpress)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSPriorityMailExpress2)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSPriorityMailInternational)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSRegisteredMail)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSSignatureConfirmation)?.length ?? 0) > 0 ||
    (barcode.match(isUSPS1)?.length ?? 0) > 0 ||
    (barcode.match(isUSPS2)?.length ?? 0) > 0 ||
    (barcode.match(isUSPS3)?.length ?? 0) > 0 ||
    (barcode.match(isUSPS4)?.length ?? 0) > 0 ||
    (barcode.match(isUSPSTracking)?.length ?? 0) > 0
  )
}
export const trackingNumberScanTest = (barcode = '') => {
  return (
    isUSPSTrackingNumber(barcode) ||
    isUPSTrackingNumber(barcode) ||
    isFedExTrackingNumber(barcode)
  )
}
export const productScanTest = (barcode = '') => {
  return (barcode.match(/\d{8}(?:\d{4,6,8})?/)?.length ?? 0) > 0
}
export const userBadgeScanTest = (barcode = '') => {
  return barcode.substring(1, 3) === 'QL'
}
export const merchantCreditGiftScanTest = (barcode = '') => {
  return (barcode.match(/GC([A-Z0-9]{14})/)?.length ?? 0) > 0
}
export const merchantCreditEgiftScanTest = (barcode = '') => {
  return (barcode.match(/EGC([A-Z0-9]{13})/)?.length ?? 0) > 0
}
export const merchantCreditScanTest = (barcode = '') => {
  return (
    merchantCreditGiftScanTest(barcode) || merchantCreditEgiftScanTest(barcode)
  )
}
export const purchaseOrderSessonGroupScanTest = (barcode = '') => {
  return (barcode.match(/^\/SUM-[a-zA-Z0-9]+\/$/)?.length ?? 0) > 0
}
export const purchaseOrderSessionScanTest = (barcode = '') => {
  return (barcode.match(/^\/POV-[0-9]+-[0-9]+\/$/)?.length ?? 0) > 0
}
export const serviceTicketScanTest = (barcode = '') => {
  return (barcode.match(/^\/SVC-[0-9]+\/$/)?.length ?? 0) > 0
}
export const inboundReturnScanTest = (barcode = '') => {
  return (barcode.match(/^\/IBR-[0-9]+\/$/)?.length ?? 0) > 0
}
export const chaosBarcodeScanTest = (barcode = '') => {
  return (
    (barcode.match(divisionRegEx)?.length ?? 0) > 0 ||
    (barcode.match(portableBinRegEx)?.length ?? 0) > 0
  )
}
const divisionRegEx = RegExp(
  /([A-Z0-9]{1,5})-(([0-9]{2})([A-Z]{2})([0-9]{2})([A-Z]{2})-([A-Z0-9]{2,6})|([0-9]{2})([A-Z]{2})([0-9]{2})([A-Z]{2})|([0-9]{2})([A-Z]{2})([0-9]{2})|([0-9]{2})([A-Z]{2})|([0-9]{2})|([A-Z]{1}[A-Z0-9]{1,5}))/
)
const portableBinRegEx = RegExp(/([A-Z0-9]{1,5})-([A-Z]{1}[A-Z0-9]{5})/)
export const parseChaosBarcodeScan = (barcode = '') => {
  const divisionSegments = divisionRegEx.exec(barcode)
  const portableBinSegments = portableBinRegEx.exec(barcode)
  // * Check if it's a PGU
  if (portableBinSegments) {
    const bin: DivisionMeta = {
      warehousePrefix: portableBinSegments[1],
      warehouseZone: null,
      warehouseZoneRow: null,
      warehouseZoneRowSection: null,
      warehouseZoneRowSectionShelf: null,
      warehouseBin: portableBinSegments[2],
      barcode: `${portableBinSegments[1]}-${portableBinSegments[2]}`,
    }
    const binInitialCharacter = bin.warehouseBin.slice(0, 1)
    const ParsedScan: BinScanFormat = {
      type: 'WAREHOUSE_BIN',
      subtype: portableBinPrefixes[binInitialCharacter],
      meta: bin,
    }
    return ParsedScan
  } else if (divisionSegments) {
    const filteredDivisionSegments = divisionSegments.filter(
      (segment) => segment
    )
    const division: DivisionMeta = {
      warehousePrefix: filteredDivisionSegments[1],
      warehouseZone: filteredDivisionSegments[3] ?? null,
      warehouseZoneRow: filteredDivisionSegments[4] ?? null,
      warehouseZoneRowSection: filteredDivisionSegments[5] ?? null,
      warehouseZoneRowSectionShelf: filteredDivisionSegments[6] ?? null,
      warehouseBin: filteredDivisionSegments[7] ?? null,
      barcode: `${filteredDivisionSegments[0]}`,
    }
    let divisionType: DivisionScanFormat['subtype'] = 'Zone'
    if (division.warehouseBin) {
      divisionType = 'Bin'
    } else if (division.warehouseZoneRowSectionShelf) {
      divisionType = 'Shelf'
    } else if (division.warehouseZoneRowSection) {
      divisionType = 'Section'
    } else if (division.warehouseZoneRow) {
      divisionType = 'Row'
    } else {
      divisionType = 'Zone'
    }
    const ParsedScan: DivisionScanFormat = {
      type: 'WAREHOUSE_DIVISION',
      subtype: divisionType,
      meta: division,
    }
    return ParsedScan
  }
  const ParsedScan: GenericScanFormat = {
    type: 'UNKNOWN_FORMAT',
    subtype: 'Merchant',
  }
  return ParsedScan
}
export const parseTrackingNumber = (barcode = ''): TrackingScanFormat => {
  const isUSPS = isUSPSTrackingNumber(barcode)
  const isUPS = isUPSTrackingNumber(barcode)
  const isFedEx = isFedExTrackingNumber(barcode)
  let subtype: TrackingScanFormat['subtype'] = 'USPS'
  if (isUSPS) {
    subtype = 'USPS'
  } else if (isUPS) {
    subtype = 'UPS'
  } else if (isFedEx) {
    subtype = 'FedEx'
  }
  return {
    type: 'TRACKING_NUMBER',
    subtype,
  }
}
export const parseProductBarcode = (barcode = ''): ProductScanFormat => {
  let subtype: ProductScanFormat['subtype'] = 'GTIN-12'
  if (barcode.length === 16) {
    subtype = 'GTIN-16'
  } else if (barcode.length === 14) {
    subtype = 'GTIN-14'
  } else if (barcode.length === 13) {
    subtype = 'GTIN-13'
  } else if (barcode.length === 8) {
    subtype = 'GTIN-8'
  }
  return {
    type: 'PRODUCT_GTIN',
    subtype,
    meta: {
      normalized: formatProductBarcode(barcode, subtype),
      isValidCheckDigit: isValidGTIN(barcode),
    },
  }
}
export const parseMerchantCredit = (barcode = ''): MerchantCreditScanFormat => {
  const isGift = merchantCreditGiftScanTest(barcode)
  const isElectronicGift = merchantCreditEgiftScanTest(barcode)
  if (isElectronicGift) {
    return {
      type: 'MERCHANT_CREDIT',
      subtype: 'eGift',
      meta: {
        referenceId: barcode.substring(3, barcode.length),
        merchantCreditTypeId: merchantCreditTypeDictionary['EGiftCard'],
      },
    }
  } else if (isGift) {
    return {
      type: 'MERCHANT_CREDIT',
      subtype: 'Gift',
      meta: {
        referenceId: barcode.substring(2, barcode.length),
        merchantCreditTypeId: merchantCreditTypeDictionary['GiftCard'],
      },
    }
  }
  throw new Error('Barcode is neither a Gift nor eGift')
}
export const parseInboundReturn = (barcode = ''): InboundReturnScanFormat => {
  const inboundReturnId = +barcode.replace(RegExp(/\//g), '').split('-')?.[1]
  return {
    type: 'INBOUND_RMA',
    subtype: inboundReturnId ? 'Merchant' : 'Amazon',
    meta: {
      inboundReturnId,
    },
  }
}
export const parsePurchaseOrder = (barcode = ''): PurchaseOrderScanFormat => {
  const content = barcode.substring(1, barcode.length - 1)
  const [prefix, purchaseOrderId, purchaseOrderSessionId] = content.split('-')
  return {
    type: 'PURCHASE_ORDER',
    subtype: 'Merchant',
    meta: {
      prefix,
      purchaseOrderId: +purchaseOrderId,
      purchaseOrderSessionId: +purchaseOrderSessionId,
    },
  }
}
export const parsePurchaseOrderSession = (
  barcode = ''
): PurchaseOrderSessionScanFormat => {
  const content = barcode.substring(1, barcode.length - 1)
  const [prefix, hash] = content.split('-')
  return {
    type: 'PURCHASE_ORDER_SESSION',
    subtype: 'Merchant',
    meta: {
      prefix,
      hash,
    },
  }
}
export const parseUserBadge = (barcode = ''): UserLoginScanFormat => {
  const [userId, checksum] = barcode.substring(3, barcode.length - 1).split('/')
  return {
    type: 'USER_LOGIN',
    subtype: 'QuickLogin',
    meta: {
      userId: +userId,
      checksum,
    },
  }
}
export const parseServiceTicket = (barcode = ''): ServiceTicketScanFormat => {
  const serviceTicketId = barcode.replace(RegExp(/\//g), '').split('-')?.[1]
  return {
    type: 'SERVICE_TICKET',
    subtype: 'Merchant',
    meta: {
      serviceTicketId: +serviceTicketId,
    },
  }
}
export const parseScan = (barcode = ''): ScanResult => {
  // TODO: Test Barcode, identify type, parse by type
  const scanTypes: ScanType[] = [
    { type: 'USER_LOGIN', test: userBadgeScanTest, parser: parseUserBadge },
    {
      type: 'MERCHANT_CREDIT',
      test: merchantCreditScanTest,
      parser: parseMerchantCredit,
    },
    {
      type: 'PURCHASE_ORDER',
      test: purchaseOrderSessionScanTest,
      parser: parsePurchaseOrder,
    },
    {
      type: 'PURCHASE_ORDER_SESSION',
      test: purchaseOrderSessonGroupScanTest,
      parser: parsePurchaseOrderSession,
    },
    {
      type: 'SERVICE_TICKET',
      test: serviceTicketScanTest,
      parser: parseServiceTicket,
    },
    {
      type: 'INBOUND_RMA',
      test: inboundReturnScanTest,
      parser: parseInboundReturn,
    },
    {
      type: 'WAREHOUSE_BIN',
      test: chaosBarcodeScanTest,
      parser: parseChaosBarcodeScan,
    },
    {
      type: 'WAREHOUSE_DIVISION',
      test: chaosBarcodeScanTest,
      parser: parseChaosBarcodeScan,
    },
    {
      type: 'PRODUCT_GTIN',
      test: productScanTest,
      parser: parseProductBarcode,
    },
    {
      type: 'TRACKING_NUMBER',
      test: trackingNumberScanTest,
      parser: parseTrackingNumber,
    },
  ]
  for (const scanType of scanTypes) {
    if (scanType.test(barcode)) {
      return scanType.parser(barcode)
    }
  }
  return {
    type: 'UNKNOWN_FORMAT',
    subtype: 'Merchant',
  }
}
