import type { LoggedInUser } from '@/services/blackfisk/auth'
import type { LabelPrinterMap, LabelTypes } from '@/stores/printer'

import { watch } from 'vue'
import { useBroadcastChannel } from '@vueuse/core'

import { useAccount } from '@/composables/useAccount'

import { useIdentityStore } from '@/stores/identity'
import { usePrinterStore } from '@/stores/printer'

export interface BROADCAST_ACCOUNT_SWITCH {
  action: 'ACCOUNT_SWITCH'
  payload: number
}
export interface BROADCAST_LOG_IN {
  action: 'LOG_IN'
  payload: LoggedInUser
}
export interface BROADCAST_LOG_OUT {
  action: 'LOG_OUT'
}
export interface BROADCAST_PRINTER_LABEL_MAP {
  action: 'PRINTER_LABEL_MAP'
  payload: LabelPrinterMap
}
export type BROADCAST_MESSAGE =
  | BROADCAST_ACCOUNT_SWITCH
  | BROADCAST_LOG_IN
  | BROADCAST_LOG_OUT
  | BROADCAST_PRINTER_LABEL_MAP
export const useAuthorizationSync = () => {
  const IdentityStore = useIdentityStore()
  const PrinterStore = usePrinterStore()
  const { updateAccount } = useAccount()
  const {
    isSupported,
    data,
    // channel,
    post,
    // close,
    // error,
    // isClosed,
  } = useBroadcastChannel<BROADCAST_MESSAGE, BROADCAST_MESSAGE>({
    name: 'bf-identity',
  })
  watch(data, () => {
    switch (data.value.action) {
      case 'ACCOUNT_SWITCH':
        if (data.value.payload > 0) {
          updateAccount(data.value.payload)
        }
        break
      case 'LOG_IN':
        IdentityStore.parseLoggedInUser(data.value.payload)
        break
      case 'LOG_OUT':
        IdentityStore.logout({ broadcast: false })
        break
      case 'PRINTER_LABEL_MAP':
        for (const [label, printerId] of Object.entries(data.value.payload)) {
          PrinterStore.updateLabelPrinterMap(
            label as keyof typeof LabelTypes,
            printerId
          )
        }
        break
    }
  })
  const broadcast = (message: BROADCAST_MESSAGE) => {
    if (isSupported) {
      post(message)
    }
  }
  return {
    data,
    broadcastAccountId: (accountId: number) => {
      broadcast({
        action: 'ACCOUNT_SWITCH',
        payload: accountId,
      })
    },
    broadcastLogin: (user: LoggedInUser) => {
      broadcast({
        action: 'LOG_IN',
        payload: user,
      })
    },
    broadcastLogout: () => {
      broadcast({
        action: 'LOG_OUT',
      })
    },
    broadcastLabelPrinterMap: (labelPrinterMap: LabelPrinterMap) => {
      broadcast({
        action: 'PRINTER_LABEL_MAP',
        payload: labelPrinterMap,
      })
    },
  }
}
