import LogRocket from 'logrocket'
import { useRouter } from 'vue-router'
import { watch } from 'vue'
import { storeToRefs } from 'pinia'

import { useScanner } from '@/composables/useScanner'

import { useIdentityStore } from '@/stores/identity'

export const useLogRocket = () => {
  if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
    const router = useRouter()
    // * Identify User to LogRocket on account switch
    const IdentityStore = useIdentityStore()
    const { userId, userName, userEmail } = storeToRefs(IdentityStore)
    watch(userEmail, () => {
      if (userEmail.value) {
        LogRocket.identify(`${userId.value}`, {
          id: userId.value,
          name: userName.value,
          email: userEmail.value,
        })
      }
    })
    // * Track Scans
    const { latestScan } = useScanner()
    watch(latestScan, () => {
      if (latestScan.value) {
        LogRocket.track('BARCODE_SCAN', {
          type: latestScan.value.type,
          subtype: latestScan.value.subtype,
          meta: JSON.stringify(latestScan.value.meta),
        })
      }
    })
    // * Identify User to LogRocket on route change
    router.beforeEach((to, from) => {
      if (userId.value > 0 && to.path !== from.path) {
        LogRocket.identify(`${userId.value}`, {
          id: userId.value,
          name: userName.value,
          email: userEmail.value,
        })
      }
    })
  }
}
