import QuickLogin from '@/components/QuickLogin.vue'
import { useDialog } from 'primevue/usedialog'
import { useScanListener } from '@/composables/useScanListener'

export const useQuickLogin = () => {
  const { listen: scanListen } = useScanListener()
  const dialog = useDialog()
  const onOpenQuickLogin = (userId: number, checksum: string) => {
    dialog.open(QuickLogin, {
      props: {
        header: 'Quick Login',
        style: {
          'width': '50vw',
          'max-width': '50vw',
        },
        breakpoints: {
          '960px': '66vw',
          '640px': '90vw',
        },
        closable: false,
        dismissableMask: true,
        modal: true,
        draggable: true,
      },
      data: {
        userId,
        checksum,
      },
    })
  }
  scanListen(
    {
      USER_LOGIN: (scan) => {
        if (scan.type === 'USER_LOGIN') {
          const userId = scan.meta.userId
          const checksum = scan.meta.checksum
          console.log('Open QuickLogin?')
          onOpenQuickLogin(userId, checksum)
        }
      },
    },
    {
      priority: 0,
      termination: false,
    }
  )
}
