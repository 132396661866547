import type { ScanResult } from '@/utils/barcode.types'

import { parseScan } from '@/utils/barcode'
import { computed, onMounted, onUnmounted, ref } from 'vue'

const scans = ref<ScanResult[]>([])
const latestScan = computed<ScanResult | undefined>(
  () => scans.value?.[scans.value.length - 1]
)
const scanReadValue = ref('')
let scanInputTimeoutId: NodeJS.Timeout | null = null
export const useScanner = () => {
  const onScanReadComplete = () => {
    scanReadValue.value = ''
  }
  const onKeyPress = (event: KeyboardEvent) => {
    const key = event.key
    const target = event.target as HTMLElement
    if (
      target === null ||
      ['input', 'select', 'textarea'].includes(
        (target?.nodeName ?? '').toLowerCase()
      )
    ) {
      return
    }
    if (scanInputTimeoutId) {
      clearTimeout(scanInputTimeoutId)
      scanInputTimeoutId = null
    }
    if (!scanInputTimeoutId) {
      scanInputTimeoutId = setTimeout(onScanReadComplete, 250)
    }
    if (key === 'Enter' || key === 'Tab') {
      const parsedScan = parseScan(scanReadValue.value)
      scans.value.push(parsedScan)
      event.preventDefault()
    } else if (!['Escape', 'Shift'].includes(key)) {
      scanReadValue.value += key
    }
  }
  const registerScanner = () => {
    onMounted(() => {
      window.removeEventListener('keydown', onKeyPress)
      window.addEventListener('keydown', onKeyPress)
    })
    onUnmounted(() => {
      window.removeEventListener('keydown', onKeyPress)
    })
  }
  return {
    registerScanner,
    latestScan,
    scans,
  }
}
