import type { ToastMessageOptions } from 'primevue/toast'

import router from '@/router'

import ToastEventBus from 'primevue/toasteventbus'
import { useErrorLog } from '@/composables/useErrorLog'
import { useSentry } from '@/composables/useSentry'

export interface ErrorToast {
  navigation: string
  message: string
  messageDetail: string
  severity: ToastMessageOptions['severity']
  report: boolean
}
export const useError = () => {
  const { Sentry } = useSentry()
  const { logErrorMessage } = useErrorLog()
  const errorToast = ({
    navigation = '',
    message = '',
    messageDetail = '',
    severity = 'error',
    report = true,
  }: Partial<ErrorToast> = {}) => {
    const assembledErrorMessage = `${message} | ${messageDetail}`
    if (message) {
      logErrorMessage([assembledErrorMessage])
      ToastEventBus.emit('add', {
        severity: severity,
        summary: message,
        detail: messageDetail,
        group: 'br',
        life: 4250,
      })
    }
    if (report) {
      Sentry.captureException(new Error(assembledErrorMessage))
    }
    if (navigation) {
      router.push({
        name: navigation,
      })
    }
  }
  return {
    errorToast,
  }
}
