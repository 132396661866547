import type { BlackfiskResponse } from '@/services/blackfisk/client'
import type { CacheRequestConfig } from 'axios-cache-interceptor'

import {
  BlackfiskRequestError,
  createClient,
} from '@/services/blackfisk/client'

export interface EntityServiceResponseError {
  message: string
  code: number | null
  reference: string | number | null
}
export interface EntityServiceResponse {
  errors: EntityServiceResponseError[]
}
export const formatErrors = (errors: EntityServiceResponseError[]) => {
  const errorsReduced = errors.reduce(
    (
      accumulator: Record<
        string,
        { count: number; messages: string[]; references: (string | number)[] }
      >,
      error
    ) => {
      if (error.code) {
        if (accumulator[error.code] === undefined) {
          accumulator[error.code] = {
            count: 0,
            messages: [],
            references: [],
          }
        }
        accumulator[error.code].count += 1
        accumulator[error.code].messages.push(error.message)
        if (error.reference) {
          accumulator[error.code].references.push(error.reference)
        }
      }
      return accumulator
    },
    {}
  )
  const { errorMessage, errorDetail } = Object.entries(errorsReduced).reduce(
    (accumulator, [code, detail]) => {
      accumulator.errorMessage += `${detail.count} x ${code} `
      accumulator.errorDetail += `${Array.from(new Set(detail.messages)).join(', ')}`
      return accumulator
    },
    {
      errorMessage: '',
      errorDetail: '',
    }
  )
  return {
    errorMessage,
    errorDetail,
  }
}
export const parsedGeneralError = (
  error: string[]
): EntityServiceResponseError[] => {
  return error.map((mapError) => ({
    code: null,
    message: mapError,
    reference: null,
  }))
}
export const parseBlackfiskBulkResponse = (
  response: BlackfiskResponse<{ errors: EntityServiceResponseError[] }[]>
) => {
  return response.data.reduce(
    (accumulator: EntityServiceResponseError[], iteratee) => {
      iteratee.errors.forEach((error) => {
        accumulator.push(error)
      })
      return accumulator
    },
    []
  )
}
export const blackfiskAxiosRequest = async <R, T = R>(
  requestConfig: CacheRequestConfig,
  dataParser: (response: BlackfiskResponse<R>) => T
) => {
  const BlackfiskClient = createClient()
  let errors: EntityServiceResponseError[] = []
  try {
    const ClientResponse =
      await BlackfiskClient<BlackfiskResponse<R>>(requestConfig)
    const { error = [] } = ClientResponse.data
    if (error) {
      errors = [...errors, ...parsedGeneralError(error)]
    }
    const data = dataParser(ClientResponse.data)
    return {
      errors,
      data,
      cached: ClientResponse.cached,
      response: ClientResponse,
    }
  } catch (err) {
    if (err instanceof BlackfiskRequestError) {
      errors.push({
        message: err.message,
        code: null,
        reference: null,
      })
    } else {
      console.error(err)
      errors.push({
        message: 'An unexpected error occurred.',
        code: null,
        reference: null,
      })
    }
  }
  return {
    errors,
    data: null,
    cached: false,
    response: null,
  }
}
